/**
 * This JS is included on an external site to add the chat popup widget
 */
var baseCdnPath = process.env.REACT_APP_CDN_SERVER;

// Fetch asset manifest
fetch(baseCdnPath + "/asset-manifest.json").then(function (res) {
  return res.json();
}).then(function (manifest) {
  if (manifest["widget.css"]) {
    loadCSS(baseCdnPath + manifest["widget.css"]);
  }
  if (manifest["widget.js"]) {
    loadJS(baseCdnPath + manifest["widget.js"], function () {
      console.log("Engage widget.js loaded");
    });
  }
});
function loadJS(url, implementationCode) {
  //url is URL of external file, implementationCode is the code
  //to be called from the file, location is the location to
  //insert the <script> element

  var scriptTag = document.createElement("script");
  scriptTag.src = url;
  scriptTag.onload = implementationCode;
  scriptTag.onreadystatechange = implementationCode;
  document.onreadystatechange = function () {
    if (document.readyState === "interactive" || document.readyState === "complete") {
      document.body.appendChild(scriptTag);
    }
  };
}
function loadCSS(href) {
  var cssId = "engage-widget-css";
  if (!document.getElementById(cssId)) {
    var head = document.getElementsByTagName("head")[0];
    var link = document.createElement("link");
    link.id = cssId;
    link.rel = "stylesheet";
    link.type = "text/css";
    link.href = href;
    link.media = "all";
    head.appendChild(link);
  }
}